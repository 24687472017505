.ucs-subnav-wrapper {
  height: 40px;
}

.ucs-subnav {
  position: relative;
}

.ucs-subnav-tabs {
  min-height: 40px;
  position: fixed;
  width: 100%;
  z-index: 1;
}

.alert {
  box-sizing: border-box;
  left: 50%;
  max-width: 50%;
  position: absolute;
  // 40px is the height of the subnav
  top: calc(var(--st-header-height) + 40px);
  transform: translateX(-50%);
  width: fit-content;
  z-index: 9999999;

  &.wrap-text {
    white-space: pre-wrap;
  }
}
